<template>
  <div class="divs">
    <ToggleSetting :listdata="listdata"></ToggleSetting>
  </div>
</template>

<script>
// let id = 1000
import ToggleSetting from '@c/ToggleSetting'
export default {
  components: {
    ToggleSetting
  },
  data() {
    return {
      listdata: [ 
        { name: '部门人员设置', link: 'PeopleManage' },
        { name: '角色设置', link: 'CharacterSet' }, 
        { name: '工作日设置', link: 'workday' }, 
         { name: '销售提成', link: 'workday2' }, 
      ]
    }
  },
  methods: {}
}
</script>
<style lang="scss">
.divs {
  font-size: 20px;
  color: red;
}
</style>
