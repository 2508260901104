<template>
  <div class="toggle">
    <ul>
      <router-link
        tag="li"
        v-for="(item, index) in listdata"
        :key="index"
        :to="item.link"
        active-class="current_ele"
      >
        <p>{{ item.name }}</p>
      </router-link>
    </ul>
    <div class="div1">
      <!-- <keep-alive> -->
        <router-view />
      <!-- </keep-alive> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    listdata: {
      type: Array,
      requiet: true
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoed lang="scss">
.toggle {
  font-size: 15px;
  color: #333;
  display: flex;
  height: 80px;
  display: block;
  flex-flow: column nowrap;
  align-items: center;
  margin-bottom: 30px;
  -moz-box-shadow: 0px 0px 10px #e8e8eb;
  -webkit-box-shadow: 0px 0px 10px #e8e8eb;
  box-shadow: 0px 0px 10px #e8e8eb;
  & > ul {
    width: 100%;
    height: 80px;
    background: #ffffff;
    border-radius: 10px;
    flex: 1;
    display: flex;
    justify-content: space-around;
    li {
      color: #333333;
      line-height: 80px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .current_ele {
    color: #fc4353;
    position: relative;
    text-shadow: 3px 6px 3px rgba(252, 80, 95, 0.4);
    &:before {
      content: '';
      width: 100%;
      height: 5px;
      border-radius: 3px;
      background: #fc4353;
      position: absolute;
      display: block;
      left: 0;
      bottom: 0;
    }
  }
}
</style>
